<template>
  <player-search :tournamentId="tournament.id"></player-search>
</template>

<script>
import { mapGetters } from 'vuex'
import PlayerSearch from '@/Pages/Player/Search'

export default {
  props: ['playersX'],
  data () {
    return {
      search: null,
      loading: false,
      listView: false,
      blockedMsg: null,
      players: null
    }
  },
  computed: {
    ...mapGetters(['tournament'])
  },
  methods: {
  },
  components: {
    PlayerSearch
  }
}
</script>
